import { getRequest, putRequest } from '../../utils'
import { authServiceUrl, bookingServiceUrl } from '../../urls'

export const getClientListReq = ({ phone, page = 0, size = 100 }) => {
  return getRequest(`${authServiceUrl}/admin/clients` + (`?phone=${phone}`) + (`&page=${page}`) + (`&size=${size}`))
}

export const getClientBalanceReq = (clientId) => {
  return getRequest(`${bookingServiceUrl}/admin/clients/${clientId}/balance`)
}

export const putClientBalanceReq = (clientId, { balance, bonuses }) => {
  return putRequest(`${bookingServiceUrl}/admin/clients/${clientId}/balance`, { balance, bonuses })
}
