import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import parse from 'autosuggest-highlight/parse'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { getGooglePlaceGeoData } from './helper'

const StreetField = ({ scriptLoaded, address, streetNumber, setFieldValue }) => {
  const [requestNewSessionToken, setRequestNewSessionToken] = React.useState<any>(false)
  const [sessionToken, setSessionToken] = React.useState<any>('')
  const getSessionToken = () => sessionToken

  const { ready, value, suggestions: { status, data }, init, setValue, clearSuggestions } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'ua' },
      location: { lat: () => 50.450001, lng: () => 30.523333 } as any,
      radius: 10 * 1000,
      language: 'ua',
      types: ['address'],
      sessionToken: getSessionToken()
    },
    initOnMount: false,
    debounce: 500
  })

  useEffect(() => {
    if (requestNewSessionToken) {
      const google = window.google
      setSessionToken(new google.maps.places.AutocompleteSessionToken())
    }
  }, [requestNewSessionToken])

  useEffect(() => {
    if (scriptLoaded) {
      init()
    }
  }, [scriptLoaded, init])

  useEffect(() => {
    if (address?.description) {
      setValue(address.description, false)
    } else {
      setValue('', false)
    }
  }, [address])

  const autoCompleteAddress = async (description, withStreetNumber) => {
    try {
      const addressDataValue = await getGooglePlaceGeoData(description, address, streetNumber)
      if (withStreetNumber) {
        setFieldValue('address', { ...addressDataValue, description })
        setFieldValue('streetNumber', addressDataValue.streetNumber)
      } else {
        setFieldValue('address', { ...addressDataValue, description })
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (streetNumber) {
      const description = `${address.street} ${streetNumber}, ${address.zipCode} ${address.city}`
      const timeOutId = setTimeout(async () => {
        await autoCompleteAddress(description, false)
      }, 500)
      return () => clearTimeout(timeOutId)
    }
  }, [streetNumber])

  const handleInput = (event, newInputValue) => {
    setValue(newInputValue)
    // setFieldValue('address', { description: newInputValue, city: null, zipCode: null, street: null, latitude: null, longitude: null })
    setRequestNewSessionToken(true)
  }

  const handleSelect = async (event: any, newValue: any) => {
    if (!newValue) {
      return
    }
    const { description, structured_formatting } = newValue
    clearSuggestions()
    const main_text = structured_formatting?.main_text?.split(',')[0]
    const textValue = main_text + ', ' + structured_formatting?.secondary_text?.replace(/,[^,]+$/, '')
    setValue(textValue, false)
    await autoCompleteAddress(description, true)
    // setStreetNumberFocus()
    setRequestNewSessionToken(false)
  }

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: 300 }}
      getOptionLabel={(option) => option?.description || ''}
      filterOptions={(x) => x}
      options={data}
      autoComplete
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => {
        return option?.place_id === value?.place_id
      }}
      value={address || ''}
      noOptionsText="Нема вулиць"
      onInputChange={handleInput}
      onChange={handleSelect}
      renderInput={(params) => (
        <TextField {...params} label="Вулиця" fullWidth/>
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || []
        const parts = parse(option.structured_formatting.main_text, matches.map((match: any) => [match.offset, match.offset + match.length]))

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }}/>
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) =>
                  <Box
                    key={index} component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                    {part.text}
                  </Box>)}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}/>
  )
}

export { StreetField }
