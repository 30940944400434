import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material'
import React, { useEffect } from 'react'
import { PageWrapper } from 'src/elements'
import { useTypedSelector } from 'src/hooks'
import Loader from 'src/elements/common/Loader'
import useActions from '../../store/useActions'

const HostList = () => {
  const isLoading = useTypedSelector(state => state.pages.host.isLoading)
  const hostList = useTypedSelector(state => state.pages.host.hostList)
  const { getHostList } = useActions()

  useEffect(() => {
    getHostList()
  }, [])

  return (
    <PageWrapper>
      <Loader isLoading={isLoading}/>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="left">Ім'я</TableCell>
              <TableCell align="left">Місто</TableCell>
              <TableCell align="left">Телефон</TableCell>
              <TableCell align="left">Дата РеєстраціЇ</TableCell>
              <TableCell align="right">Дії</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {hostList?.map((row) => {
              return (
                <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">{row._id}</TableCell>
                  <TableCell align="left">{row.name} {row.surname}</TableCell>
                  <TableCell align="left">{row.city}</TableCell>
                  <TableCell align="left">{row.phone}</TableCell>
                  <TableCell align="left">{row.createdAt}</TableCell>
                  <TableCell align="right" width="120px">
                  </TableCell>
                </TableRow>)
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </PageWrapper>
  )
}

export default HostList
