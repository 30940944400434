import { configureStore } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import { createReduxHistoryContext } from 'redux-first-history'
import spy from 'redux-monitor-spy'
import saga from 'redux-saga'
import { Environment } from 'src/types/enums'
import { rootReducer } from './rootReducer'
import RootSaga from './rootSaga'

export const sagaMiddleware = saga()

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory()
  })

const store = configureStore({
  reducer: rootReducer(routerReducer),
  middleware: [routerMiddleware, sagaMiddleware, spy],
  devTools: process.env.NODE_ENV !== Environment.PRODUCTION
})

export const history = createReduxHistory(store)

sagaMiddleware.run(RootSaga)

export type RootState = ReturnType<typeof store.getState>

export default store
