import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isLoading: false as boolean,
    formErrorMessage: null as string,
    serverErrorMessage: null as string
  },
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setFormErrorMessage: (state, { payload }: PayloadAction<string>) => {
      state.formErrorMessage = payload
    },
    setServerErrorMessage: (state, { payload }: PayloadAction<string>) => {
      state.serverErrorMessage = payload
    }
  }
})

export const { setIsLoading, setFormErrorMessage, setServerErrorMessage } = loginSlice.actions
export const loginUser = createAction<{ username: string, password: string }>('login/loginUser')

export const loginReducer = loginSlice.reducer
