import { AppBar, Avatar, Box, ClickAwayListener, Container, Grow, MenuItem, MenuList, Paper, Popper, Tab, Toolbar, Typography } from '@mui/material'
import React, { FC, useRef, useState } from 'react'
import { useTypedSelector } from '../../../hooks'
import { stringAvatar } from '../../../utils/avatar'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import useActions from '../../../store/auth/useActions'
import { pages } from '../../../utils'
import { EmojiPeople, HailOutlined, HailRounded, HailSharp, Man, Man2Outlined, Man2Rounded, ManSharp, People, PeopleOutline, PlaylistAdd, PlaylistAddCheck, PlaylistRemove, SendRounded, Settings, SupervisedUserCircle, SupervisedUserCircleTwoTone, VerifiedUser } from '@mui/icons-material'

type Props = {}

const NavigationTab = ({ label, path, icon }) => {
  const location = useLocation()

  const isActive = location.pathname === path

  return (
    <Tab label={label} component={Link} to={path} icon={icon}
         sx={{
           minWidth: 'unset', mr: 2, textTransform: 'initial',
           ...(isActive && { opacity: 1, color: 'primary.main', borderBottom: '4px solid transparent', borderColor: 'primary.main' }),
         }}/>
  )
}

export const Header: FC<Props> = () => {
  const accessToken = useTypedSelector(store => store.auth.accessToken)
  const username = useTypedSelector(store => store.auth.username)
  const navigate = useNavigate()
  const anchorRef = useRef(null)
  const [profileIsOpen, setProfileIsOpen] = useState(false)
  const { logoutUser } = useActions()

  const navigateToLogin = () => {
    navigate('/login')
  }

  const toggleProfile = () => {
    setProfileIsOpen(!profileIsOpen)
  }

  return (
    <>
      <AppBar position="static" sx={{ zIndex: 12 }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography variant="h6" noWrap component="a" href="/" sx={{ ml: 1, mr: 2, display: 'flex', fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.3rem', color: 'inherit', textDecoration: 'none', }}>
              <svg width="100" height="30" viewBox="0 0 921 382" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M48.0919 32.2788C39.2328 39.8722 36.2798 45.7783 36.2798 58.0122C36.2798 101.042 105.887 108.214 116.433 66.4494C125.292 31.0132 76.7784 6.96716 48.0919 32.2788Z" fill="#FFFFFF"/>
                <path d="M850.648 32.7007C843.476 39.4504 840.101 47.4658 840.101 58.0123C840.101 80.3708 855.288 93.8704 880.178 93.8704C905.068 93.8704 920.254 80.3708 920.254 58.0123C920.254 35.6537 905.068 22.1542 880.178 22.1542C865.413 22.1542 858.241 24.6853 850.648 32.7007Z" fill="#FFFFFF"/>
                <path d="M696.668 83.3239V110.745H677.685H658.701V142.384V174.024H677.685H696.668V219.163C696.668 293.832 710.168 310.285 775.134 312.394L814.789 314.081V284.129V254.177H793.696C767.541 254.177 764.166 248.693 764.166 206.085V174.024H789.478H814.789V142.384V110.745H789.478H764.166V83.3239V55.903H730.417H696.668V83.3239Z" fill="#FFFFFF"/>
                <path d="M505.566 115.385C484.051 126.775 473.083 145.759 473.083 171.492C473.083 209.038 498.394 232.662 544.799 238.99C568.423 242.365 576.438 247.005 573.064 255.864C568.845 266.411 540.58 265.145 537.627 254.177C535.518 246.583 531.721 245.74 500.082 245.74H464.646L467.177 257.13C477.301 299.738 503.878 317.456 557.455 317.456C594.578 317.456 614.406 310.284 632.546 290.879C650.264 271.895 653.217 235.615 638.452 212.835C630.015 199.757 615.25 192.585 582.766 186.679C546.908 179.508 538.471 175.289 545.221 167.274C552.392 158.836 565.048 160.102 570.954 169.805C575.595 176.976 581.079 178.242 608.922 178.242H641.827V166.008C641.827 149.556 625.796 127.619 605.969 116.229C583.188 103.151 529.612 102.729 505.566 115.385Z" fill="#FFFFFF"/>
                <path d="M40.9204 113.697C39.233 114.963 37.9674 161.367 37.9674 216.631C37.9674 311.549 37.5455 317.455 29.9521 321.674C25.3116 323.783 16.8744 325.893 10.9684 325.893C0.42186 325.893 0 326.736 0 353.735V382L35.0144 379.891C62.4353 378.625 72.9818 376.094 83.1064 368.922C107.152 351.626 109.684 337.705 109.684 218.318V110.744H76.7785C58.6385 110.744 42.186 112.01 40.9204 113.697Z" fill="#FFFFFF"/>
                <path d="M844.319 211.991V313.237H880.177H916.036V211.991V110.744H880.177H844.319V211.991Z" fill="#FFFFFF"/>
                <path d="M282.31 1.79077C274.703 4.77499 138.199 104.846 134.704 110.019C126.892 121.558 128.948 138.07 139.844 148.018C146.833 154.583 162.869 156.97 170.886 152.991C173.559 151.599 201.517 131.505 233.177 108.626C264.836 85.548 291.767 66.8469 293 66.8469C294.233 66.8469 321.164 85.548 352.823 108.626C384.483 131.505 412.441 151.599 415.114 152.991C423.131 156.97 439.167 154.583 446.156 148.018C457.052 138.07 459.108 121.558 451.296 110.019C447.595 104.448 310.885 4.57604 303.073 1.79077C296.7 -0.596613 288.477 -0.596613 282.31 1.79077Z" fill="#FFFFFF"/>
                <path d="M265.864 110.019C229.887 118.573 199.873 147.222 190.211 183.033C186.716 195.566 186.305 227.597 189.388 240.529C195.35 265.596 217.553 294.046 240.166 305.784C271.003 321.7 310.474 322.097 343.161 306.778C349.534 303.794 360.224 295.836 367.831 288.475C389.211 267.984 398.873 244.309 398.873 212.676C398.873 182.038 388.594 156.97 367.831 136.877C342.545 112.406 301.84 101.663 265.864 110.019ZM303.279 173.085C330.415 180.247 339.666 220.833 319.314 242.717C302.868 260.424 272.031 256.842 260.724 235.754C253.618 222.5 253.716 202.5 259.696 191.388C264.836 181.839 273.264 175.075 282.721 173.085C294.028 170.698 294.028 170.698 303.279 173.085Z" fill="#FFFFFF"/>
              </svg>
            </Typography>
            <Typography component="h1" variant="h6"
                        color="inherit" noWrap sx={{ flexGrow: 1 }}>
              Панель адміністратора
            </Typography>
            {!accessToken && <Avatar sx={{ cursor: 'pointer' }} onClick={navigateToLogin}/>}
            {accessToken && <Avatar {...stringAvatar(username)} ref={anchorRef} onClick={toggleProfile}/>}
            <Popper open={profileIsOpen}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                    sx={{ zIndex: 10 }}>
              {({ TransitionProps, placement }) =>
                <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom', }}>
                  <Paper>
                    <ClickAwayListener onClickAway={toggleProfile}>
                      <MenuList autoFocusItem={profileIsOpen}
                                id="composition-menu"
                                aria-labelledby="composition-button">
                        <MenuItem>Профіль</MenuItem>
                        <MenuItem>Налаштування</MenuItem>
                        <MenuItem onClick={logoutUser}>Вийти</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>}
            </Popper>
          </Toolbar>
        </Container>
      </AppBar>
      {accessToken &&
        <AppBar position="sticky" color="default" sx={{ zIndex: 9 }}>
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              <Box>
                <NavigationTab label="Мої оголошення" icon={<PlaylistAdd/>} path="/"/>
                <NavigationTab label="Мої угоди" icon={<PlaylistAddCheck/>} path={pages.bookingList}/>
                <NavigationTab label="Архів" icon={<PlaylistRemove/>} path={pages.archiveList}/>
                <NavigationTab label="Кліенти" icon={<SupervisedUserCircle/>} path={pages.clientList}/>
                <NavigationTab label="Хости" icon={<HailRounded/>} path={pages.hostList}/>
                <NavigationTab label="Розсилка" icon={<SendRounded/>} path={pages.notifications}/>
                <NavigationTab label="Налаштування" icon={<Settings/>} path={pages.settings}/>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>}
    </>
  )
}
