import { Alert, Avatar, Box, Button, CircularProgress, Container, Grid, Link, TextField, Typography } from '@mui/material'
import React, { FC } from 'react'
import { PageWrapper } from 'src/elements'
import { useTypedSelector } from '../../hooks'
import useActions from './store/useActions'
import { pages } from '../../utils'
import { Navigate } from 'react-router-dom'
import { HowToRegOutlined } from '@mui/icons-material'

type Props = {}

const Registration: FC<Props> = () => {
  const isLoading = useTypedSelector(store => store.pages.registration.isLoading)
  const accessToken = useTypedSelector(store => store.auth.accessToken)
  const usernameErrorMessage = useTypedSelector(store => store.pages.registration.usernameErrorMessage)
  const passwordErrorMessage = useTypedSelector(store => store.pages.registration.passwordErrorMessage)
  const serverErrorMessage = useTypedSelector(store => store.pages.registration.serverErrorMessage)
  const { registerUser, setUsernameErrorMessage, setPasswordErrorMessage } = useActions()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const password = data.get('password') as string
    const passwordConfirm = data.get('passwordConfirm') as string
    if (password !== passwordConfirm) {
      setPasswordErrorMessage('Passwords do not match')
    } else {
      registerUser({
        username: data.get('username') as string,
        password
      })
    }
  }

  const clearErrorMessage = () => {
    setUsernameErrorMessage(null)
    setPasswordErrorMessage(null)
  }

  if (accessToken) {
    return <Navigate to={{ pathname: pages.flatList }}/>
  }

  return (
    <PageWrapper>
      <Container component="main" maxWidth="xs">
        {serverErrorMessage &&
          <Alert severity="error">{serverErrorMessage}</Alert>}
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <HowToRegOutlined/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Реєстрація
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField margin="normal"
                       required
                       fullWidth
                       id="username"
                       label="Логін"
                       name="username"
                       error={!!usernameErrorMessage}
                       helperText={usernameErrorMessage}
                       onChange={clearErrorMessage}
                       autoFocus/>
            <TextField margin="normal"
                       required
                       fullWidth
                       name="password"
                       label="Пароль"
                       type="password"
                       id="password"
                       error={!!passwordErrorMessage}
                       helperText={passwordErrorMessage}
                       onChange={clearErrorMessage}/>
            <TextField margin="normal"
                       required
                       fullWidth
                       name="passwordConfirm"
                       label="Повторіть пароль"
                       type="password"
                       id="passwordConfirm"
                       error={!!passwordErrorMessage}
                       helperText={passwordErrorMessage}
                       onChange={clearErrorMessage}/>
            <Button type="submit" fullWidth
                    variant="contained" sx={{ mt: 3, mb: 2 }}
                    disabled={isLoading}>
              {isLoading && <CircularProgress size={25}/>}
              {!isLoading && 'Зареєструватися'}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href={pages.login} variant="body2">Вже зареєстровані?</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </PageWrapper>
  )
}

export default Registration
