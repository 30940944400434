import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { FC, useRef } from 'react'
import { Carousel } from 'react-responsive-carousel'
import useActions from '../../store/useActions'
import { CheckBox, CheckBoxOutlineBlank, Delete, Save } from '@mui/icons-material'
import { FormGroup } from '../../../../elements'
import { StreetField } from './StreetField'
import { useJsApiLoader } from '@react-google-maps/api'
import { amenities, cities, currencies, districts, metroStations, residences } from './constant'

const GOOGLE_MAPS_API_KEY = 'AIzaSyBiHNsJE0pRfblN72gVfHFhntPCDaIsaSI'
const GOOGLE_MAPS_API_LIBRARIES: any = ['places']

type Props = {
  initialFlat: any
}

const FlatForm: FC<Props> = ({ initialFlat }) => {
  const { createFlat, updateFlat, addFlatImages } = useActions()
  const imageInput = useRef(null)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script', googleMapsApiKey: GOOGLE_MAPS_API_KEY, language: 'en', region: 'EN', version: 'weekly', libraries: GOOGLE_MAPS_API_LIBRARIES
  })

  const addImages = (images = [], setFieldValue, e) => {
    addFlatImages({
      files: e.target.files,
      callback: (data) => setFieldValue('images', [...images, ...data])
    })
  }

  const deleteImage = (images, setFieldValue, imgSrc) => {
    setFieldValue('images', images.filter(img => img !== imgSrc))
  }

  const handleSubmit = async values => {
    if (initialFlat?._id) {
      await updateFlat({ flat: values, _id: initialFlat._id })
    } else {
      await createFlat({ flat: values })
    }
  }

  return (
    <Formik
      initialValues={initialFlat}
      onSubmit={values => handleSubmit(values)}
      validateOnMount>
      {({ values, handleChange, setFieldValue, resetForm }: any) => (
        <>
          <Form>
            <FormGroup text="Загальна інформація">
              <Box sx={{ display: 'grid', gap: '10px', gridTemplateColumns: 'repeat(4, 1fr)' }}>
                <TextField
                  label="Ім'я власника"
                  name="ownerName"
                  variant="outlined"
                  value={values.ownerName || ''}
                  onChange={handleChange}/>
                <Autocomplete
                  disablePortal
                  options={cities}
                  value={cities.find(city => city.value === values.city)?.label || ''}
                  onChange={(e, value) => {
                    // @ts-ignore
                    setFieldValue('city', value?.value)
                  }}
                  renderInput={(params) => <TextField {...params} label="Місто"/>}/>

                {isLoaded &&
                  <StreetField {...{ scriptLoaded: isLoaded, address: values.address, streetNumber: values.streetNumber, setFieldValue }}/>}
                <TextField
                  label="Будинок"
                  name="streetNumber"
                  variant="outlined"
                  value={values.streetNumber || ''}
                  onChange={handleChange}/>
                <Autocomplete
                  disablePortal
                  options={districts}
                  value={districts.find(district => district.value === values.district)?.label || ''}
                  onChange={(e, value) => {
                    // @ts-ignore
                    setFieldValue('district', value?.value)
                  }}
                  renderInput={(params) => <TextField {...params} label="Район"/>}/>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={metroStations}
                  disableCloseOnSelect
                  // @ts-ignore
                  getOptionLabel={(option) => option.label}
                  limitTags={1}
                  onChange={(e, value) => {
                    // @ts-ignore
                    setFieldValue('metroStations', value?.map(station => station.value))
                  }}
                  value={values.metroStations?.map(value => metroStations.find(station => station.value === value)) || []}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small"/>}
                        checkedIcon={<CheckBox fontSize="small"/>}
                        style={{ marginRight: 8 }}
                        checked={selected}/>
                      {/*@ts-ignore*/}
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Метро" placeholder="Станції"/>
                  )}/>
                <Autocomplete
                  disablePortal
                  options={residences}
                  value={residences.find(residence => residence.value === values.residence)?.label || ''}
                  onChange={(e, value) => {
                    // @ts-ignore
                    setFieldValue('residence', value?.value || null)
                  }}
                  renderInput={(params) => <TextField {...params} label="Житловий комплекс"/>}/>
                <TextField
                  label="Площа"
                  type="number"
                  name="additionalInfo.size"
                  variant="outlined"
                  value={values.additionalInfo?.size || ''}
                  onChange={handleChange}/>
                <TextField
                  label="Поверх"
                  type="number"
                  name="additionalInfo.floor"
                  variant="outlined"
                  value={values.additionalInfo?.floor || ''}
                  onChange={handleChange}/>
                <TextField
                  label="Поверхів у будинку"
                  type="number"
                  name="additionalInfo.floorsInBuilding"
                  variant="outlined"
                  value={values.additionalInfo?.floorsInBuilding || ''}
                  onChange={handleChange}/>
                <TextField
                  label="Кількість кімнат"
                  type="number"
                  name="roomsNumber"
                  variant="outlined"
                  value={values.roomsNumber || ''}
                  onChange={handleChange}/>
              </Box>
              <Box sx={{ display: 'grid', gap: '10px', gridTemplateColumns: 'repeat(4, 1fr)', marginTop: '30px' }}>
                <TextField
                  label="Ціна"
                  type="number"
                  name="minPrice"
                  variant="outlined"
                  value={values.minPrice || ''}
                  onChange={handleChange}/>
                <Autocomplete
                  disablePortal
                  options={currencies}
                  value={currencies.find(curr => curr.value === values.currency)?.label || currencies[0].label}
                  onChange={(e, value) => {
                    // @ts-ignore
                    setFieldValue('currency', value?.value)
                  }}
                  renderInput={(params) => <TextField {...params} label="Валюта"/>}/>
                <FormControlLabel
                  control={<Switch
                    name="noCommission"
                    checked={values.noCommission}
                    onChange={handleChange}/>}
                  sx={{ width: '25ch', marginLeft: '10px' }}
                  label="Без комісії"/>
              </Box>
            </FormGroup>

            <FormGroup text="Додаткова інформація">
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginTop: '20px', width: '50%' }}>
                <FormControlLabel
                  control={<Switch
                    name="additionalInfo.wifi"
                    checked={!!values.additionalInfo?.wifi}
                    value={true}
                    onChange={handleChange}/>}
                  sx={{ width: '25ch' }}
                  label="Wi-Fi"/>
                <FormControlLabel
                  control={<Switch
                    name="additionalInfo.boiler"
                    checked={!!values.additionalInfo?.boiler}
                    value={true}
                    onChange={handleChange}/>}
                  sx={{ width: '25ch' }}
                  label="Бойлер"/>
                <FormControlLabel
                  control={<Switch
                    name="additionalInfo.ac"
                    checked={!!values.additionalInfo?.ac}
                    value={true}
                    onChange={handleChange}/>}
                  sx={{ width: '25ch' }}
                  label="Кондиціонер"/>
                <FormControlLabel
                  control={<Switch
                    name="additionalInfo.washMachine"
                    checked={!!values.additionalInfo?.washMachine}
                    value={true}
                    onChange={handleChange}/>}
                  sx={{ width: '25ch' }}
                  label="Пральна машина"/>
                <FormControlLabel
                  control={<Switch
                    name="additionalInfo.furnished"
                    checked={!!values.additionalInfo?.furnished}
                    value={true}
                    onChange={handleChange}/>}
                  sx={{ width: '25ch' }}
                  label="Меблі"/>
                <FormControlLabel
                  control={<Switch
                    name="additionalInfo.tv"
                    checked={!!values.additionalInfo?.tv}
                    value={true}
                    onChange={handleChange}/>}
                  sx={{ width: '25ch' }}
                  label="Телевізор"/>
              </Box>
              <Box sx={{ width: '100%', marginTop: '20px' }}>
                <Autocomplete
                  multiple
                  id="checkboxes-additional-info"
                  options={amenities}
                  disableCloseOnSelect
                  // @ts-ignore
                  getOptionLabel={(option) => option.label}
                  // limitTags={1}
                  onChange={(e, values) => {
                    setFieldValue('amenities', {})
                    values.forEach(value => {
                      // @ts-ignore
                      setFieldValue('amenities.' + value.value, true)
                    })
                  }}
                  // value={values.amenities?.map?.(value => amenities.find(station => station.value === value)) || []}
                  value={amenities.filter(am => !!values.amenities?.[am.value]) || []}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small"/>}
                        checkedIcon={<CheckBox fontSize="small"/>}
                        style={{ marginRight: 8 }}
                        checked={selected}/>
                      {/*@ts-ignore*/}
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="В квартирі є" placeholder="Зручності"/>
                  )}/>
              </Box>
            </FormGroup>

            <FormGroup text="Правила проживання">
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginTop: '20px', }}>
                <FormControl>
                  <FormLabel id="children-group-label">Можна з дітьми</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="children-group-label"
                    name="livingRules.children"
                    value={values.livingRules?.children || ''}
                    onChange={handleChange}>
                    <FormControlLabel value="NOT_SPECIFIED" control={<Radio/>} label="Невідомо"/>
                    <FormControlLabel value="NOT_ALLOWED" control={<Radio/>} label="Ні, не можна"/>
                    <FormControlLabel value="ALLOWED" control={<Radio/>} label="Так, можна"/>
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginTop: '20px', }}>
                <FormControl>
                  <FormLabel id="animal-group-label">Можна з деякими тваринами</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="animal-group-label"
                    name="livingRules.animals"
                    value={values.livingRules?.animals || ''}
                    onChange={handleChange}>
                    <FormControlLabel value="NOT_SPECIFIED" control={<Radio/>} label="Невідомо"/>
                    <FormControlLabel value="NOT_ALLOWED" control={<Radio/>} label="Ні, не можна"/>
                    <FormControlLabel value="ALLOWED" control={<Radio/>} label="Так, можна"/>
                  </RadioGroup>
                </FormControl>
              </Box>
            </FormGroup>

            <FormGroup text="Про житло">
              <TextField
                label="Опис"
                name="desc"
                sx={{ width: '100%' }}
                multiline
                rows={5}
                variant="outlined"
                value={values.desc || ''}
                onChange={handleChange}/>
              <TextField
                label="Посилання на відеотур"
                name="videoLink"
                sx={{ width: '100%', mt: '24px' }}
                variant="outlined"
                value={values.videoLink || ''}
                onChange={handleChange}/>

              <Box sx={{ display: 'grid', gap: '20px', marginTop: '20px', alignItems: 'center', gridTemplateColumns: '1fr 1fr', justifyItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <input
                    ref={imageInput} name="images" accept=".jpg,.jpeg,.png,.svg,.webp,.gif"
                    onChange={e => addImages(values.images, setFieldValue, e)}
                    id="file-upload" type="file" style={{ display: 'none' }} multiple/>
                  <Button
                    sx={{ width: '25ch' }}
                    onClick={() => imageInput.current.click()}
                    variant={values.images?.length ? 'outlined' : 'contained'}>
                    Завантажити фото
                  </Button>
                  <Typography variant="subtitle1" sx={{ mt: '10px' }}>Додайте не менше 3-х фотографій обьєкту</Typography>
                </Box>
                <Box sx={{ width: '50ch', minHeight: '300px', borderRadius: 3, borderWidth: 1, borderStyle: 'solid', borderColor: 'grey.400', overflow: 'hidden', backgroundColor: 'grey.200' }}>
                  {!!values.images?.length &&
                    <Carousel width="auto" dynamicHeight={false} showThumbs={false} key={values.images?.length}>
                      {values.images?.map((img, i) =>
                        <Box key={i} sx={{ display: 'flex', alignItems: 'center', height: '300px', overflow: 'hidden', position: 'relative' }}>
                          <img src={img} alt="img-url" style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'cover' }}/>
                          <Button variant="contained" style={{ position: 'absolute', bottom: '50px', left: '50%', transform: 'translateX(-50%)' }}
                                  onClick={() => deleteImage(values.images, setFieldValue, img)}>
                            Видалити
                          </Button>
                        </Box>)}
                    </Carousel>}
                </Box>
              </Box>
            </FormGroup>

            <FormGroup text="Інформація про власника (не відобр. у додатку)">
              <TextField
                label="Номер власника"
                name="owner.number"
                sx={{ width: '100%' }}
                variant="outlined"
                value={values.owner?.number || ''}
                onChange={handleChange}/>
              <TextField
                label="Загальна інформація"
                name="owner.info"
                sx={{ width: '100%', marginTop: '30px' }}
                multiline
                rows={5}
                variant="outlined"
                value={values.owner?.info || ''}
                onChange={handleChange}/>
            </FormGroup>

            <Box sx={{ marginTop: '30px', display: 'flex', gap: '30px' }}>
              <Button onClick={() => resetForm({ ...initialFlat })} variant="contained" color="secondary" startIcon={<Delete/>}>
                Відміна
              </Button>
              <Button variant="contained" type="submit" startIcon={<Save/>}>
                Зберегти оголошення
              </Button>
            </Box>
          </Form>
        </>)}
    </Formik>
  )
}

export { FlatForm }
