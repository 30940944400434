import { all, fork } from 'redux-saga/effects'
import { watchFlatsApi } from 'src/pages/Flat/store/sagas'
import { watchCommonApi } from 'src/store/common/sagas'
import { watchAuthApi } from './auth/sagas'
import { watchLoginApi } from '../pages/Login/store/sagas'
import { watchRegistrationApi } from '../pages/Registration/store/sagas'
import { watchBookingsApi } from '../pages/Booking/store/sagas'
import { watchArchivesApi } from '../pages/Archive/store/sagas'
import { watchHostApi } from '../pages/Host/store/sagas'
import { watchNotificationsApi } from '../pages/Notifications/store/sagas'
import { watchClientApi } from '../pages/Client/store/sagas'

export default function* RootSaga() {
  yield all([fork(watchCommonApi)])
  yield all([fork(watchFlatsApi)])
  yield all([fork(watchBookingsApi)])
  yield all([fork(watchArchivesApi)])
  yield all([fork(watchHostApi)])
  yield all([fork(watchAuthApi)])
  yield all([fork(watchLoginApi)])
  yield all([fork(watchRegistrationApi)])
  yield all([fork(watchNotificationsApi)])
  yield all([fork(watchClientApi)])
}
