import { createAction, createSlice, PayloadAction, } from '@reduxjs/toolkit'

const prefix = 'pages/notification'

export const clearStore = createAction(`${prefix}/clearStore`)

const notificationsSlice = createSlice({
  name: prefix,
  initialState: {
    isLoading: false as boolean,
  },
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
  },
  extraReducers: {
    [clearStore.type]: () => ({
      isLoading: false,
    }),
  },
})

export const { setIsLoading } = notificationsSlice.actions

export const sendNotification = createAction<{ title: string, description: string, test: boolean }>(`${prefix}/sendNotification`)

export const notificationsReducer = notificationsSlice.reducer
