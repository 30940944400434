import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { PageWrapper } from 'src/elements'
import { useTypedSelector } from 'src/hooks'
import Loader from 'src/elements/common/Loader'
import useActions from '../../store/useActions'
import { Add, DeleteOutlined, Send } from '@mui/icons-material'
import { getCityText } from '../../../../utils/address'
import { getBookingStatusText } from '../../../../utils/bookingStatus'
import EditIcon from '@mui/icons-material/Edit'
import { getFlatEditPage } from '../../../../utils'
import { useNavigate } from 'react-router-dom'
import { getFormattedDateDay } from '../../../../utils/date'

const BookingList = () => {
  const navigate = useNavigate()
  const isLoading = useTypedSelector(state => state.pages.bookings.isLoading)
  const bookingList = useTypedSelector(state => state.pages.bookings.bookingList)
  const { getBookingList, removeBooking, sendNotificationContact, sendNotificationNotActual } = useActions()
  const [bookingIdToRemove, setBookingIdToRemove] = useState()
  const [flatToAddContact, setFlatToAddContact] = useState(null)

  useEffect(() => {
    getBookingList()
  }, [])

  const handleEditFlat = (id) => {
    navigate(getFlatEditPage(id))
  }

  const openRemoveDialog = (bookingId) => {
    setBookingIdToRemove(bookingId)
  }

  const handleRemoveFlatBookings = () => {
    removeBooking({ bookingId: bookingIdToRemove })
    setBookingIdToRemove(null)
  }

  const handleAddOwnerClick = (row) => {
    setFlatToAddContact(row._id)
  }

  const handleNavigateToOwnerClick = (row) => {
    window.open(row.owner.link, '_blank')
  }

  const handleSendNotificationContactClick = (booking) => {
    sendNotificationContact({ bookingId: booking._id })
  }

  const handleSendNotificationNotActualClick = (booking) => {
    sendNotificationNotActual({ bookingId: booking._id })
  }

  return (
    <PageWrapper>
      <Loader isLoading={isLoading}/>
      <TableContainer component={Paper} sx={{ width: '100%', maxWidth: '100%' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="left">Фото</TableCell>
              <TableCell align="left">Власник</TableCell>
              <TableCell align="left">Місто</TableCell>
              {/*<TableCell align="left">Адреса</TableCell>*/}
              {/*<TableCell align="left">Бронювання</TableCell>*/}
              <TableCell align="left">Телефон</TableCell>
              <TableCell align="left">Ім'я</TableCell>
              <TableCell align="left">Статус</TableCell>
              <TableCell align="left">Ресурс</TableCell>
              <TableCell align="left">Контакт</TableCell>
              <TableCell align="left">Сповіщення</TableCell>
              <TableCell align="left">Дата</TableCell>
              <TableCell align="right">Дії</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {bookingList.map((booking) => {
              const flat = booking.flat
              if (!flat) {
                return (
                  <TableRow key={booking._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">{booking._id}</TableCell>
                    <TableCell width="100px">
                    </TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">+{booking.user?.phone}</TableCell>
                    <TableCell align="left">{booking.user?.surname} {booking.user?.name} {booking.user?.middleName}</TableCell>
                    <TableCell align="left" sx={{ color: 'initial' }}>
                      {getBookingStatusText(booking.status)}
                    </TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">
                    </TableCell>
                    <TableCell align="left">
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      {getFormattedDateDay(new Date(booking.createdAt))}
                    </TableCell>
                    <TableCell align="right" width="120px">
                      <IconButton aria-label="to-flats" onClick={() => openRemoveDialog(booking._id)}>
                        <DeleteOutlined/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              }
              return (
                <TableRow key={flat._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {flat.publicId}
                  </TableCell>
                  <TableCell width="100px">
                    <Box sx={{ maxWidth: '50px', maxHeight: '50px', borderRadius: 1, overflow: 'hidden', border: '1px solid transparent', borderColor: 'grey.400', backgroundColor: 'grey.200' }}>
                      <img src={flat?.images ? flat?.images[0] : flat?.imageUrl} width="50px" height="50px" alt="Flat"/>
                    </Box>
                  </TableCell>
                  {flat.crmId ?
                    <TableCell align="left">CRM ID: {flat.crmId}</TableCell> :
                    <TableCell align="left">{flat.ownerName}<br/> {flat.owner?.number}</TableCell>}
                  <TableCell align="left">{getCityText(flat.city)}</TableCell>
                  {/*<TableCell align="left">{row.address?.description} ({getDistrictText(row.district)})</TableCell>*/}
                  {/*<TableCell align="left">{getBookingTypeText(row.booking?.bookingType)}</TableCell>*/}
                  <TableCell align="left">+{booking.user?.phone}</TableCell>
                  <TableCell align="left">{booking.user?.surname} {booking.user?.name} {booking.user?.middleName}</TableCell>
                  <TableCell align="left" sx={{ color: 'initial' }}>
                    {getBookingStatusText(booking.status)}
                  </TableCell>
                  <TableCell align="left">{flat.crmSource}</TableCell>
                  <TableCell align="left">
                    {(!flat.owner?.number && flat.owner?.link) &&
                      <Button onClick={() => handleNavigateToOwnerClick(flat)} variant="contained" startIcon={<Add/>}>
                        контакт
                      </Button>}
                    {flat.owner?.number || ''}
                    {/*{isContactMode &&*/}
                    {/*  <Grid display="grid" gridTemplateColumns="1fr 1fr 1fr" alignItems="center">*/}
                    {/*    <IconButton onClick={() => handleNavigateToOwnerClick(row)}>*/}
                    {/*      <OpenInNew/>*/}
                    {/*    </IconButton>*/}
                    {/*    <TextField label="Контакт"*/}
                    {/*               name="number"*/}
                    {/*               variant="standard"*/}
                    {/*               value={''}*/}
                    {/*               sx={{ width: '100px' }}*/}
                    {/*               onChange={() => {*/}
                    {/*               }}/>*/}
                    {/*    <IconButton onClick={() => handleAddOwnerClick(row)}>*/}
                    {/*      <Save/>*/}
                    {/*    </IconButton>*/}
                    {/*  </Grid>}*/}
                  </TableCell>
                  <TableCell align="left">
                    {(flat.owner?.number) &&
                      <Button onClick={() => handleSendNotificationContactClick(booking)} variant="contained" startIcon={<Send/>}>
                        відправити
                      </Button>}
                    {(!flat.owner?.number) &&
                      <Button onClick={() => handleSendNotificationNotActualClick(booking)} variant="contained" color="error" startIcon={<Send/>}>
                        Неактуальний
                      </Button>}
                  </TableCell>
                  <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                    {getFormattedDateDay(new Date(booking.createdAt))}
                  </TableCell>
                  <TableCell align="right" width="120px">
                    <IconButton aria-label="edit" onClick={() => handleEditFlat(flat._id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton aria-label="to-flats" onClick={() => openRemoveDialog(booking._id)}>
                      <DeleteOutlined/>
                    </IconButton>
                  </TableCell>
                </TableRow>)
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={!!bookingIdToRemove}
              onClose={() => setBookingIdToRemove(null)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Скасувати броньювання цієї угоди?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Після підтвердження це оголошення буде доступно усім користувачам
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBookingIdToRemove(null)} color="primary">
            Відмова
          </Button>
          <Button onClick={handleRemoveFlatBookings} color="primary" autoFocus>
            Погодити
          </Button>
        </DialogActions>
      </Dialog>
    </PageWrapper>
  )
}

export default BookingList
