import { put, takeLatest } from 'redux-saga/effects'
import * as actions from './slices'
import { getHostListReq } from '../../../api/host'

export function* getHostListApi() {
  yield put(actions.setIsLoading(true))
  try {
    const { data } = yield getHostListReq()
    yield put(actions.setHostList(data.hosts))
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* watchHostApi() {
  yield takeLatest(actions.getHostList.type, getHostListApi)
}
