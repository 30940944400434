export const getItem = key => localStorage.getItem(key)

export const setItem = (key: string, value: string) =>
  localStorage.setItem(key, value)

export const removeItem = key => localStorage.removeItem(key)

export const setToken = (token: string) => localStorage.setItem('accessToken', token)

export const getToken = () => localStorage.getItem('accessToken') || null

export const removeToken = () => removeItem('accessToken')
