import { Color } from 'src/utils'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: 'Mulish', sans-serif;
    font-size: 15px;
    background: ${Color.white};
  }
  
  input,
  select,
  button {
    font-family: 'Mulish', sans-serif;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]:hover,
  input[type="number"]:focus {
    -moz-appearance: number-input;
  }

  #versions tr:not(.last) :before {
    display: none;
  }
`
