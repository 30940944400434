import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

const registrationSlice = createSlice({
  name: 'registration',
  initialState: {
    isLoading: false as boolean,
    usernameErrorMessage: null as string,
    passwordErrorMessage: null as string,
    serverErrorMessage: null as string
  },
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setUsernameErrorMessage: (state, { payload }: PayloadAction<string>) => {
      state.usernameErrorMessage = payload
    },
    setPasswordErrorMessage: (state, { payload }: PayloadAction<string>) => {
      state.passwordErrorMessage = payload
    },
    setServerErrorMessage: (state, { payload }: PayloadAction<string>) => {
      state.serverErrorMessage = payload
    }
  }
})

export const { setIsLoading, setUsernameErrorMessage, setPasswordErrorMessage, setServerErrorMessage } = registrationSlice.actions
export const registerUser = createAction<{ username: string, password: string }>('registration/registerUser')

export const registrationReducer = registrationSlice.reducer
