import { put, takeLatest } from 'redux-saga/effects'
import * as actions from './slices'
import { setAccessToken, setUsername } from './slices'

export function* logoutUserSaga() {
  // yield postLogoutReq()  todo: needed when refreshToken exists
  yield put(setAccessToken(null))
  yield put(setUsername(null))
}

export function* watchAuthApi() {
  yield takeLatest(actions.logoutUser.type, logoutUserSaga)
}
