import { getGeocode, getLatLng, getZipCode } from 'use-places-autocomplete'

const setDataFromGooglePlaces = (array, addressProperty, searchValue) => {
  array.forEach((item => {
    if (item.types?.[0] === searchValue) {
      addressProperty = item.long_name
    }
  }))
  return addressProperty || null
}

export const getGooglePlaceGeoData = async (description, addressData, streetNumber) => {
  const geocodeResults = await getGeocode({ address: description, language: 'ua' })
  const { lat, lng } = getLatLng(geocodeResults[0])
  const zipCodeResult = getZipCode(geocodeResults[0], false)
  return {
    city: setDataFromGooglePlaces(geocodeResults[0].address_components, addressData?.city, 'locality'),
    street: setDataFromGooglePlaces(geocodeResults[0].address_components, addressData?.street, 'route'),
    streetNumber: setDataFromGooglePlaces(geocodeResults[0].address_components, streetNumber, 'street_number'),
    zipCode: zipCodeResult,
    latitude: lat,
    longitude: lng,
  }
}
