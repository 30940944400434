import { combineReducers } from 'redux'
import { flatsReducer } from 'src/pages/Flat/store/slices'
import { commonReducer } from './common/slices'
import { authReducer } from './auth/slices'
import { loginReducer } from '../pages/Login/store/slices'
import { registrationReducer } from '../pages/Registration/store/slices'
import { bookingsReducer } from '../pages/Booking/store/slices'
import { archivesReducer } from '../pages/Archive/store/slices'
import { hostReducer } from '../pages/Host/store/slices'
import { notificationsReducer } from '../pages/Notifications/store/slices'
import { clientReducer } from '../pages/Client/store/slices'

export const rootReducer = router =>
  combineReducers({
    router,
    pages: combineReducers({
      flats: flatsReducer,
      login: loginReducer,
      registration: registrationReducer,
      bookings: bookingsReducer,
      archives: archivesReducer,
      host: hostReducer,
      notifications: notificationsReducer,
      client: clientReducer,
    }),
    common: commonReducer,
    auth: authReducer
  })
