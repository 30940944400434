import { createAction, createSlice, PayloadAction, } from '@reduxjs/toolkit'

const prefix = 'pages/client'

export const clearStore = createAction(`${prefix}/clearStore`)

const clientSlice = createSlice({
  name: prefix,
  initialState: {
    isLoading: false as boolean,
    clientList: [] as any,
    clientsTotal: 0,
    clientBalance: {} as any
  },
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setClientList: (state, { payload }: PayloadAction<any>) => {
      state.clientList = payload
    },
    setClientsTotal: (state, { payload }: PayloadAction<any>) => {
      state.clientsTotal = payload
    },
    setClientBalance: (state, { payload }: PayloadAction<any>) => {
      state.clientBalance = payload
    }
  },
  extraReducers: {
    [clearStore.type]: () => ({
      isLoading: false,
      clientList: [],
      clientsTotal: 0,
      clientBalance: {}
    }),
  },
})

export const { setIsLoading, setClientList, setClientsTotal, setClientBalance } = clientSlice.actions

export const getClientList = createAction<{ phone: string, page: number, size: number }>(`${prefix}/getClientList`)
export const appendClientList = createAction<{ phone: string, page: number, size: number }>(`${prefix}/appendClientList`)
export const getClientBalance = createAction<{ clientId: string }>(`${prefix}/getClientBalance`)
export const updateClientBalance = createAction<{ clientId: string, balance: number, bonuses: number }>(`${prefix}/updateClientBalance`)

export const clientReducer = clientSlice.reducer
