import axios from 'axios'
import { getToken, removeToken, setItem } from 'src/utils'
import store from '../store'
import { logoutUser } from '../store/auth/slices'

const axiosInstance = axios.create()

axiosInstance.interceptors.response.use(
  res => res,
  error => {
    if (error.response.status === 401
      && !error.request.responseURL.includes('/admin/auth/login')) {
      removeToken()
      setItem('redirectUrl', window.location.href)
      store.dispatch(logoutUser())
    }
    return Promise.reject(error)
  },
)

enum MethodEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

const makeRequest = async (
  url: string, method: MethodEnum, data: Record<string, any> = {}, responseType: any = '',
  params: Record<string, any> = {}, passedHeaders: any = null, withBearerAuth: boolean = true) => {
  const headers = passedHeaders || {
    'Content-Type': 'application/json',
  }
  let accessToken = getToken()
  if (accessToken && withBearerAuth) {
    headers['X-Authorization'] = `Bearer ${accessToken}`
  }
  return axiosInstance({
    url,
    method,
    data,
    headers,
    responseType,
    params,
  })
}

const getRequest = (
  url: string,
  params?: any,
  responseType = '',
  headers?: any,
) => makeRequest(url, MethodEnum.GET, {}, responseType, params, headers)

const postRequest = (
  url: string,
  data: Record<string, any>,
  params?: Record<string, any>,
  headers?: any,
) => makeRequest(url, MethodEnum.POST, data, '', params, headers)

const putRequest = (
  url: string,
  data: any,
  params?: Record<string, any>,
  headers?: any,
  withBearerAuth?: boolean
) => makeRequest(url, MethodEnum.PUT, data, '', params, headers, withBearerAuth)

const deleteRequest = (url: string) => makeRequest(url, MethodEnum.DELETE)

export { getRequest, postRequest, putRequest, deleteRequest }
