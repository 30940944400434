export const cities = [
  { value: 'kiev', label: 'Київ' },
  { value: 'lviv', label: 'Львів' },
  { value: 'kharkiv', label: 'Харків' },
  { value: 'odesa', label: 'Одеса' },
  { value: 'dnipro', label: 'Дніпро' },
  { value: 'ivano_frankivsk', label: 'Івано-Франківськ' },
]

export const currencies = [
  { label: 'гривень', value: 'UAH' },
  { label: 'доларів', value: 'USD' }
]

export const districts = [
  { label: 'Шевченківський', value: 'SHEVCHENKIVSKYI' },
  { label: 'Подільський', value: 'PODILSKY' },
  { label: 'Голосіївський', value: 'HOLOSIIVSKYI' },
  { label: 'Дніпровський', value: 'DNIPROVSKY' },
  { label: 'Дарницький', value: 'DARNYTSKYI' },
  { label: 'Оболонський', value: 'OBOLONSKY' },
  { label: 'Святошинський', value: 'SVYATOSHYN' },
  { label: 'Солом\'янський', value: 'SOLOMYANSKY' },
  { label: 'Печерський', value: 'PECHERSK' },
  { label: 'Деснянський', value: 'DESNYAN' },
]

export const metroStations = [
  // Kiev
  { branch: null, label: 'Немає', value: 'NO_METRO' },
  { branch: 'M1', label: 'Академмістечко', value: 'AKADEMMISTECHKO' },
  { branch: 'M1', label: 'Арсенальна', value: 'ARSENALNA' },
  { branch: 'M1', label: 'Берестейська', value: 'BERESTEISKA' },
  { branch: 'M3', label: 'Бориспільська', value: 'BORYSPILSKA' },
  { branch: 'M2', label: 'Васильківська', value: 'VASYLKIWSKA' },
  { branch: 'M1', label: 'Вокзальна', value: 'VOKZALNA' },
  { branch: 'M3', label: 'Видубичі', value: 'VYDUBYCHI' },
  { branch: 'M3', label: 'Вирлиця', value: 'VYRLYTSIA' },
  { branch: 'M2', label: 'Виставковий центр', value: 'VYSTAVKOVYI_TSENTR' },
  { branch: 'M2', label: 'Героїв Дніпра', value: 'HEROIV_DNIPRA' },
  { branch: 'M1', label: 'Гідропарк', value: 'HIDROPARK' },
  { branch: 'M2', label: 'Голосіївська', value: 'HOLOSIIVSKA' },
  { branch: 'M1', label: 'Дарниця', value: 'DARNYTSIA' },
  { branch: 'M2', label: 'Деміївська', value: 'DEMIIVSKA' },
  { branch: 'M1', label: 'Дніпро', value: 'DNIPRO' },
  { branch: 'M3', label: 'Дорогожичі', value: 'DOROHOZHYCHI' },
  { branch: 'M3', label: 'Дружби Народів', value: 'DRUZHBY_NARODIV' },
  { branch: 'M1', label: 'Житомирська', value: 'ZHYTOMYRSKA' },
  { branch: 'M3', label: 'Золоті Ворота', value: 'ZOLOTI_VOROTA' },
  { branch: 'M2', label: 'Іподром', value: 'IPODROM' },
  { branch: 'M3', label: 'Кловська', value: 'KLOVSKA' },
  { branch: 'M2', label: 'Контрактова Площа', value: 'KONTRAKTOVA_PLOSCHA' },
  { branch: 'M1', label: 'Лівобережна', value: 'LIVOBEREZHNA' },
  { branch: 'M1', label: 'Лісова', value: 'LISOVA' },
  { branch: 'M3', label: 'Лук`янівська', value: 'LUKYANIVSKA' },
  { branch: 'M2', label: 'Либідська', value: 'LYBIDSKA' },
  { branch: 'M2', label: 'Майдан Незалежності', value: 'MAIDAN_NEZALEZHNOSTI' },
  { branch: 'M2', label: 'Мінська', value: 'MINSKA' },
  { branch: 'M1', label: 'Нивки', value: 'NYVKY' },
  { branch: 'M2', label: 'Оболонь', value: 'OBOLON' },
  { branch: 'M2', label: 'Олімпійська', value: 'OLIMPIISKA' },
  { branch: 'M3', label: 'Осокорки', value: 'OSOKORKY' },
  { branch: 'M3', label: 'Палац Спорту', value: 'PALATS_SPORTU' },
  { branch: 'M2', label: 'Палац Україна', value: 'PALATS_UKRAYINA' },
  { branch: 'M2', label: 'Петрівка', value: 'PETRIVKA' },
  { branch: 'M3', label: 'Печерська', value: 'PECHERSKA' },
  { branch: 'M2', label: 'Площа Льва Толстого', value: 'PLOSCHA_LVA_TOLSTOHO' },
  { branch: 'M3', label: 'Позняки', value: 'POZNYAKI' },
  { branch: 'M1', label: 'Політехнічний Інститут', value: 'POLITEKHNICHNYI_INSTYTUT' },
  { branch: 'M2', label: 'Поштова Площа', value: 'POSHTOVA_PLOSHCHA' },
  { branch: 'M1', label: 'Святошин', value: 'SVYATOSHYN' },
  { branch: 'M3', label: 'Славутич', value: 'SLAVUTYCH' },
  { branch: 'M3', label: 'Сирець', value: 'SYRETS' },
  { branch: 'M2', label: 'Тараса Шевченка', value: 'TARASA_SHEVCHENKA' },
  { branch: 'M1', label: 'Театральна', value: 'TEATRALNA' },
  { branch: 'M2', label: 'Теремки', value: 'TEREMKY' },
  { branch: 'M1', label: 'Університет', value: 'UNIVERSITET' },
  { branch: 'M3', label: 'Харківська', value: 'KHARKIVSKA' },
  { branch: 'M1', label: 'Хрещатик', value: 'KHRESCHATYK' },
  { branch: 'M3', label: 'Червоний Хутір', value: 'CHERVONIY_KHUTIR' },
  { branch: 'M1', label: 'Чернігівська', value: 'CHERNIHIVSKA' },
  { branch: 'M1', label: 'Шулявська', value: 'SHULYAVSKA' },

  // Kharkiv
  { branch: 'M1', label: 'Холодна гора', value: 'KHOLODNA_HORA' },
  { branch: 'M1', label: 'Вокзальна', value: 'VOKZALNA' },
  { branch: 'M1', label: 'Центральний ринок', value: 'CENTRALNYY_RYNOK' },
  { branch: 'M1', label: 'Майдан Конституції', value: 'MAIDAN_KONSTYTUTSII' },
  { branch: 'M1', label: 'Проспект Гагаріна', value: 'PROSPEKT_GAGARINA' },
  { branch: 'M1', label: 'Спортивна', value: 'SPORTYVNA' },
  { branch: 'M1', label: 'Завод імені Малишева', value: 'ZAVOD_IMENI_MALYSHEVA' },
  { branch: 'M1', label: 'Турбоатом', value: 'TURBOATOM' },
  { branch: 'M1', label: 'Палац Спорту', value: 'PALATS_SPORTU' },
  { branch: 'M1', label: 'Армійська', value: 'ARMIISKA' },
  { branch: 'M1', label: 'Імені О.С. Масельського', value: 'IMENI_OS_MASELSKOHO' },
  { branch: 'M1', label: 'Тракторний завод', value: 'TRAKTORNYI_ZAVOD' },
  { branch: 'M1', label: 'Індустріальна', value: 'INDUSTRIALNA' },
  { branch: 'M2', label: 'Героїв Праці', value: 'HEROIV_PRATSI' },
  { branch: 'M2', label: 'Студентська', value: 'STUDENTSKA' },
  { branch: 'M2', label: 'Академіка Павлова', value: 'AKADEMIKA_PAVLOVA' },
  { branch: 'M2', label: 'Академіка Барабашова', value: 'AKADEMIKA_BARABASHOVA' },
  { branch: 'M2', label: 'Київська', value: 'KYIVSKA' },
  { branch: 'M2', label: 'Ярослава Мудрого', value: 'YAROSLAVA_MUDROHO' },
  { branch: 'M2', label: 'Університет', value: 'UNIVERSYTET' },
  { branch: 'M2', label: 'Історичний музей', value: 'ISTORYCHNYI_MUZEY' },
  { branch: 'M3', label: 'Перемога', value: 'PEREMOHA' },
  { branch: 'M3', label: 'Олексіївська', value: 'OLEKSIIVSKA' },
  { branch: 'M3', label: '23 Серпня', value: '23_SERPNYA' },
  { branch: 'M3', label: 'Ботанічний сад', value: 'BOTANICHNYY_SAD' },
  { branch: 'M3', label: 'Наукова', value: 'NAUKOVA' },
  { branch: 'M3', label: 'Держпром', value: 'DERZHPRROM' },
  { branch: 'M3', label: 'Архітектора Бекетова', value: 'ARKHITEKTORA_BEKETOVA' },
  { branch: 'M3', label: 'Захисників України', value: 'ZAKHYSNYKIV_UKRAYINY' },
  { branch: 'M3', label: 'Метробудівників', value: 'METROBUDIVNYKIV' }
]

export const residences = [
  { label: 'Rybalsky', value: 'Rybalsky' },
  { label: 'Skyline', value: 'Skyline' },
  { label: 'Світлопарк', value: 'Світлопарк' },
  { label: 'Щасливий', value: 'Щасливий' },
  { label: 'Podil Plaza', value: 'Podil Plaza' },
  { label: 'Новопечерска вежа', value: 'Новопечерска вежа' },
  { label: 'Central Park', value: 'Central Park' },
  { label: 'Crystal Parl', value: 'Crystal Parl' },
  { label: 'Crystal Hills', value: 'Crystal Hills' },
  { label: 'Артемівський', value: 'Артемівський' },
  { label: 'Diamond Hills', value: 'Diamond Hills' },
  { label: 'Панорама на Печерську', value: 'Панорама на Печерську' },
  { label: 'Richmond', value: 'Richmond' },
  { label: 'Підвисоцький', value: 'Підвисоцький' },
  { label: 'Brooklyn', value: 'Brooklyn' },
  { label: 'Park Avenue', value: 'Park Avenue' },
  { label: 'Park Avenue VIP', value: 'Park Avenue VIP' },
  { label: 'Медовий', value: 'Медовий' },
  { label: 'Південний Кварлат', value: 'Південний Кварлат' },
  { label: 'Victory V', value: 'Victory V' },
  { label: 'Лідер', value: 'Лідер' },
  { label: 'Cardinal', value: 'Cardinal' },
  { label: 'Tetris Hall', value: 'Tetris Hall' },
  { label: 'Chealsea Tower', value: 'Chealsea Tower' },
  { label: 'Alter Ego', value: 'Alter Ego' },
  { label: 'Нивки Gарк', value: 'Нивки Gарк' },
  { label: 'Салют', value: 'Салют' },
  { label: 'Кадетський гай', value: 'Кадетський гай' },
  { label: 'Sky Avenue', value: 'Sky Avenue' },
  { label: 'Sherwood', value: 'Sherwood' },
  { label: 'Малахіт', value: 'Малахіт' },
  { label: 'UNIT City', value: 'UNIT City' },
  { label: 'Royal Tower', value: 'Royal Tower' },
  { label: 'NVER', value: 'NVER' },
  { label: 'Boston', value: 'Boston' },
  { label: 'UNO', value: 'UNO' },
  { label: 'Manhattan City', value: 'Manhattan City' },
  { label: 'DOCK32', value: 'DOCK32' },
  { label: 'Каховський', value: 'Каховський' },
  { label: 'Альпійський', value: 'Альпійський' },
  { label: 'Династія', value: 'Династія' },
  { label: 'Смарагдовий', value: 'Смарагдовий' },
  { label: 'Адамант', value: 'Адамант' },
  { label: 'Олександрія', value: 'Олександрія' },
  { label: 'Jack House', value: 'Jack House' },
  { label: 'Зарічний', value: 'Зарічний' },
  { label: 'Славутич', value: 'Славутич' },
  { label: 'River Stone', value: 'River Stone' },
  { label: 'Рев\'єра', value: 'Рев\'єра' },
  { label: 'Сонячна рев\'єра', value: 'Сонячна рев\'єра' },
  { label: 'Сонячна брама', value: 'Сонячна брама' },
  { label: 'Carnegie Tower', value: 'Carnegie Tower' },
  { label: 'Signature', value: 'Signature' },
  { label: 'Файна таун', value: 'Файна таун' },
  { label: 'Республіка', value: 'Республіка' },
  { label: 'Комфорт таун', value: 'Комфорт таун' },
  { label: 'Комфорт сіті', value: 'Комфорт сіті' },
  { label: 'Мегасіті', value: 'Мегасіті' },
  { label: 'Лебединий', value: 'Лебединий' },
  { label: 'Олександрівський', value: 'Олександрівський' },
  { label: 'Квартет', value: 'Квартет' },
  { label: 'Ok Land', value: 'Ok Land' },
  { label: 'Time', value: 'Time' },
  { label: 'Пори року', value: 'Пори року' },
  { label: '4 сезона', value: '4 сезона' },
  { label: 'Хвиля', value: 'Хвиля' },
  { label: 'Pechersky', value: 'Pechersky' },
  { label: 'AVENUE 14-16', value: 'AVENUE 14-16' },
  { label: 'Новопечерський Двір', value: 'Новопечерський Двір' },
  { label: 'Новопечерські Липки', value: 'Новопечерські Липки' },
  { label: 'Busov Hill', value: 'Busov Hill' },
  { label: 'Regent Hill', value: 'Regent Hill' },
  { label: 'Манхеттен', value: 'Манхеттен' },
  { label: 'Chicago', value: 'Chicago' },
  { label: 'New York', value: 'New York' },
  { label: 'Bristol', value: 'Bristol' },
  { label: '044', value: '044' },
  { label: 'Soho Residence', value: 'Soho Residence' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'Бульвар фонтанів', value: 'Бульвар фонтанів' },
  { label: 'Дипломат Холл', value: 'Дипломат Холл' },
  { label: 'Перлина 52', value: 'Перлина 52' },
  { label: 'Елегант', value: 'Елегант' },
  { label: 'Діамант', value: 'Діамант' },
  { label: 'Отрада', value: 'Отрада' },
  { label: 'Family Friends', value: 'Family Friends' },
  { label: 'Патріотика', value: 'Патріотика' },
  { label: 'Нова Англія', value: 'Нова Англія' },
  { label: 'Лікоград', value: 'Лікоград' },
  { label: 'Львівський квартал', value: 'Львівський квартал' },
  { label: 'Ярославів град', value: 'Ярославів град' },
  { label: 'Fiord', value: 'Fiord' },
  { label: 'Престиж Холл', value: 'Престиж Холл' },
  { label: 'Таріян таверс', value: 'Таріян таверс' },
  { label: 'Французький квартал 1', value: 'Французький квартал 1' },
  { label: 'Французький квартал 2', value: 'Французький квартал 2' },
  { label: 'Obolonsky', value: 'Obolonsky' },
  { label: 'Паркове місто', value: 'Паркове місто' },
  { label: 'Місто квітів', value: 'Місто квітів' },
  { label: 'Варшавський Квартал', value: 'Варшавський Квартал' },
  { label: 'Караваєві дачі', value: 'Караваєві дачі' },
  { label: 'Campus', value: 'Campus' },
  { label: 'Elyseum', value: 'Elyseum' },
  { label: 'Аристократ', value: 'Аристократ' },
  { label: 'L-квартал', value: 'L-квартал' },
  { label: 'Козацький', value: 'Козацький' },
  { label: 'Крістер град', value: 'Крістер град' },
  { label: 'Зелений острів', value: 'Зелений острів' },
  { label: 'Зелений острів 2', value: 'Зелений острів 2' },
  { label: 'Seven', value: 'Seven' },
]

export const amenities = [
  { label: 'Фен', value: 'hairDryer' },
  { label: 'Душова кабінка', value: 'showerCabin' },
  { label: 'Ванна', value: 'bath' },
  { label: 'Базові зручності', value: 'basic' },
  { label: 'Засоби прибирання', value: 'cleaningProducts' },
  { label: 'Праска', value: 'iron' },
  { label: 'Дошка для прасування', value: 'ironBoard' },
  { label: 'Пилосос', value: 'vacuumCleaner' },
  { label: 'Постільна білизна', value: 'linens' },
  { label: 'Матрац', value: 'mattress' },
  { label: 'Вішаки', value: 'hangers' },
  { label: 'Супутникове телебачення', value: 'satelliteTv' },
  { label: 'Централізоване опалення', value: 'centralHeating' },
  { label: 'Генератор', value: 'generator' },
  { label: 'Сигналізація', value: 'alarms' },
  { label: 'Сейф', value: 'safe' },
  { label: 'Аптечка першої допомоги', value: 'firstAidKit' },
  { label: 'Вогнегасник', value: 'extinguisher' },
  { label: 'Окреме робоче місце', value: 'workplace' },
  { label: 'Посуд', value: 'dishes' },
  { label: 'Холодильник', value: 'fridge' },
  { label: 'Кухонна плита', value: 'stove' },
  { label: 'Духова шафа', value: 'oven' },
  { label: 'Мікрохвильова піч', value: 'microwave' },
  { label: 'Посудомийна машина', value: 'dishwasher' },
  { label: 'Чайник', value: 'kettle' },
  { label: 'Кавомашина', value: 'coffeeMachine' },
  { label: 'Мультиварка', value: 'multicooker' },
  { label: 'Лоджія або балкон', value: 'balcony' },
  { label: 'Безкоштовний вуличний паркінг', value: 'parking' },
  { label: 'Ліфт', value: 'elevator' },
]
