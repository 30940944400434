import { createAction, createSlice, PayloadAction, } from '@reduxjs/toolkit'

const prefix = 'pages/host'

export const clearStore = createAction(`${prefix}/clearStore`)

const hostSlice = createSlice({
  name: prefix,
  initialState: {
    isLoading: false as boolean,
    hostList: [] as any
  },
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setHostList: (state, { payload }: PayloadAction<any>) => {
      state.hostList = payload
    }
  },
  extraReducers: {
    [clearStore.type]: () => ({
      isLoading: false,
      hostList: [],
    }),
  },
})

export const { setIsLoading, setHostList } = hostSlice.actions

export const getHostList = createAction(`${prefix}/getHostList`)

export const hostReducer = hostSlice.reducer
