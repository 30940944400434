import { deleteRequest, getRequest, postRequest, putRequest } from '../../utils'
import { realtyServiceUrl } from '../../urls'
import axios from 'axios'

export const getFlatListReq = (hasBookings, isArchived, city, publicId, crmSource, page = 0, size = 100) => {
  return getRequest(`${realtyServiceUrl}/admin/realties`
    + (`?page=${page}`)
    + (`&size=${size}`)
    + (hasBookings != null ? `&hasBookings=${hasBookings}` : '')
    + (isArchived != null ? `&isArchived=${isArchived}` : '')
    + (city ? `&city=${city}` : '')
    + (publicId ? `&publicId=${publicId}` : '')
    + (crmSource ? `&crmSource=${crmSource}` : ''))
}

export const postFlatReq = flat => postRequest(`${realtyServiceUrl}/admin/realties`, { flat })

export const putFlatReq = (realtyId, realty) => putRequest(`${realtyServiceUrl}/admin/realties/${realtyId}`, { realty })

export const getFlatReq = realtyId => getRequest(`${realtyServiceUrl}/admin/realties/${realtyId}`, {})

export const removeFlatReq = realtyId => deleteRequest(`${realtyServiceUrl}/admin/realties/${realtyId}`)

export const presignImageReq = (extension) => getRequest(`${realtyServiceUrl}/admin/images`, { extension })

export const putImageReq = (file, signData) => {
  const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${signData.cloudname}/auto/upload`
  const formData = new FormData()
  formData.append('file', file)
  formData.append('public_id', signData.fileName)
  formData.append('api_key', signData.apikey)
  formData.append('signature', signData.signature)
  formData.append('timestamp', signData.paramsToSign.timestamp)
  formData.append('eager', signData.paramsToSign.eager)
  formData.append('folder', signData.paramsToSign.folder)
  formData.append('format', signData.paramsToSign.format)
  return axios.post(cloudinaryUrl, formData)
}
