import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ModalNames, PushPayload } from './types'

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    isLoading: true,
    openedModal: '' as ModalNames,
    profile: {
      permissions: [],
      roles: []
    } as any
  },
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    toggleModal: (state, { payload }: PayloadAction<ModalNames>) => {
      state.openedModal = payload === state.openedModal ? '' : payload
    },
    setProfile: (state, { payload }: PayloadAction<any>) => {
      state.profile = payload
    }
  }
})

export const { setIsLoading, toggleModal, setProfile } = commonSlice.actions

export const push = createAction<PushPayload>('common/push')

export const commonReducer = commonSlice.reducer
