import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { PageWrapper } from 'src/elements'
import { useTypedSelector } from 'src/hooks'
import Loader from 'src/elements/common/Loader'
import { DeleteOutlined, PlaylistAdd } from '@mui/icons-material'
import useActions from '../../store/useActions'
import { getCityText, getDistrictText, getStreetText } from '../../../../utils/address'

const ArchiveList = () => {
  const isLoading = useTypedSelector(state => state.pages.archives.isLoading)
  const flatList = useTypedSelector(state => state.pages.archives.flatList)
  const { getFlatList, removeFlatFromArchive, removeFlat } = useActions()
  const [flatIdToRemove, setFlatIdToRemove] = useState()

  useEffect(() => {
    getFlatList()
  }, [])

  const handleRemoveFromArchive = (flat) => {
    removeFlatFromArchive({ flat })
  }

  const handleDeleteFlat = () => {
    removeFlat({ flatId: flatIdToRemove })
    setFlatIdToRemove(null)
  }

  return (
    <PageWrapper>
      <Loader isLoading={isLoading}/>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="left">Фото</TableCell>
              <TableCell align="left">Повне Ім'я</TableCell>
              <TableCell align="left">Місто</TableCell>
              <TableCell align="left">Район</TableCell>
              <TableCell align="left">Адреса</TableCell>
              <TableCell align="left">Створено</TableCell>
              <TableCell align="right">Дії</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {flatList.map((row) => {
              return (
                <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.publicId}
                  </TableCell>
                  <TableCell width="100px">
                    <Box sx={{ maxWidth: '50px', maxHeight: '50px', borderRadius: 1, overflow: 'hidden', border: '1px solid transparent', borderColor: 'grey.400', backgroundColor: 'grey.200' }}>
                      <img src={row?.images ? row?.images[0] : row?.imageUrl} width="50px" height="50px" alt="Flat"/>
                    </Box>
                  </TableCell>
                  <TableCell align="left">{row.ownerName}</TableCell>
                  <TableCell align="left">{getCityText(row.city)}</TableCell>
                  <TableCell align="left">{getDistrictText(row.district)}</TableCell>
                  <TableCell align="left">{getStreetText(row.address)}</TableCell>
                  <TableCell align="left">{row.createdBy}</TableCell>
                  <TableCell align="right" width="120px">
                    <IconButton aria-label="to-flats" onClick={() => handleRemoveFromArchive(row)}>
                      <PlaylistAdd/>
                    </IconButton>
                    <IconButton aria-label="to-flats" onClick={() => setFlatIdToRemove(row._id)}>
                      <DeleteOutlined/>
                    </IconButton>
                  </TableCell>
                </TableRow>)
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={!!flatIdToRemove}
              onClose={() => setFlatIdToRemove(null)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Видалити це оголошення?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Після підтвердження це оголошення буде повністю видалено без можливості повернути його
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFlatIdToRemove(null)} color="primary">
            Відмова
          </Button>
          <Button onClick={handleDeleteFlat} color="primary" autoFocus>
            Погодити
          </Button>
        </DialogActions>
      </Dialog>
    </PageWrapper>
  )
}

export default ArchiveList
