import { CircularProgress } from '@mui/material'
import * as React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgb(255, 255, 255);
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface Props {
  isLoading: boolean
}

const Loader: React.FC<Props> = ({ isLoading }) =>
  isLoading && (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  )

export default Loader
