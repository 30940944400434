import { put, takeLatest } from 'redux-saga/effects'
import * as actions from './slices'
import { sendNotificationReq } from '../../../api/notifications'

export function* sendNotificationApi(action) {
  yield put(actions.setIsLoading(true))
  try {
    const { payload: { title, description, test } } = action
    yield sendNotificationReq({ title, description, test })
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* watchNotificationsApi() {
  yield takeLatest(actions.sendNotification.type, sendNotificationApi)
}
