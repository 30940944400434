import { put, takeLatest } from 'redux-saga/effects'
import * as actions from './slices'
import { setIsLoading, setServerErrorMessage, setUsernameErrorMessage } from './slices'
import { setAccessToken, setUsername } from '../../../store/auth/slices'
import { postUserReq } from '../../../api/user'

export function* registerUserSaga(action: ReturnType<typeof actions.registerUser>) {
  const { payload: { username, password } } = action
  yield put(setIsLoading(true))
  yield put(setUsername(username))
  try {
    let { data } = yield postUserReq(username, password)
    yield put(setAccessToken(data.accessToken))
  } catch (err) {
    yield put(setUsernameErrorMessage(err.response?.data?.message))
    yield put(setServerErrorMessage(err.response?.data?.message))
  }
  yield put(setIsLoading(false))
}

export function* watchRegistrationApi() {
  yield takeLatest(actions.registerUser.type, registerUserSaga)
}
