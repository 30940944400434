import { Box, Button, CircularProgress, TextField, } from '@mui/material'
import React from 'react'
import { FormGroup, PageWrapper } from 'src/elements'
import { Form, Formik } from 'formik'
import { SendSharp } from '@mui/icons-material'
import { useTypedSelector } from '../../../hooks'
import useActions from '../store/useActions'

const Notifications = () => {
  const isLoading = useTypedSelector(state => state.pages.notifications.isLoading)
  const { sendNotification } = useActions()

  const handleTestClick = async values => {
    if (isLoading) {
      return
    }
    sendNotification({ title: values.title, description: values.description, test: true })
  }

  const handleSendClick = async values => {
    if (isLoading) {
      return
    }
    sendNotification({ title: values.title, description: values.description, test: false })
  }

  return (
    <PageWrapper>
      <Formik
        initialValues={{ title: '🏡Щогодини нові квартири!', description: 'Не дайте своїм уявленням про ідеальне житло залишитися тільки в голові. Завітайте в Josti і знайдіть свою майбутню квартиру вже зараз!' }}
        onSubmit={() => {
        }}
        validateOnMount>
        {({ values, handleChange }: any) => (
          <>
            <Form>
              <FormGroup text="Надіслати сповіщення">
                <Box sx={{ display: 'grid', gap: '10px', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                  <TextField
                    label="Заголовок"
                    name="title"
                    variant="outlined"
                    value={values.title || ''}
                    onChange={handleChange}/>
                </Box>
                <Box sx={{ display: 'grid', gap: '10px', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                  <TextField
                    label="Опис"
                    name="description"
                    sx={{ width: '100%', marginTop: '30px' }}
                    multiline
                    rows={5}
                    variant="outlined"
                    value={values.description || ''}
                    onChange={handleChange}/>
                </Box>
                <Box sx={{ marginTop: '30px', display: 'flex', gap: '30px' }}>
                  <Button variant="outlined" type="button" startIcon={<SendSharp/>} onClick={() => handleTestClick(values)}>
                    {isLoading ? <CircularProgress/> : 'Відправити тест'}
                  </Button>
                  <Button variant="contained" type="button" startIcon={<SendSharp/>} onClick={() => handleSendClick(values)}>
                    {isLoading ? <CircularProgress/> : 'Відправити всім'}
                  </Button>
                </Box>
              </FormGroup>
            </Form>
          </>)}
      </Formik>
    </PageWrapper>
  )
}

export default Notifications
