const env = window.REACT_APP_ENVIRONMENT ? window.REACT_APP_ENVIRONMENT : ''

export const authServiceUrl = env === 'local' ? 'http://localhost:3000' :
  `https://${env === 'live' ? '' : (window.REACT_APP_ENVIRONMENT + '.')}auth.josti.com.ua`

export const realtyServiceUrl = env === 'local' ? 'http://localhost:3000' :
  `https://${env === 'live' ? '' : (window.REACT_APP_ENVIRONMENT + '.')}realty.josti.com.ua`

export const bookingServiceUrl = env === 'local' ? 'http://localhost:3000' :
  `https://${env === 'live' ? '' : (window.REACT_APP_ENVIRONMENT + '.')}booking.josti.com.ua`
