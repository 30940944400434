import { Alert, Avatar, Box, Button, Checkbox, CircularProgress, Container, FormControlLabel, Grid, Link, TextField, Typography } from '@mui/material'
import React, { FC } from 'react'
import { PageWrapper } from 'src/elements'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useTypedSelector } from '../../hooks'
import useActions from './store/useActions'
import { pages } from '../../utils'
import { Navigate } from 'react-router-dom'

type Props = {}

const Login: FC<Props> = () => {
  const isLoading = useTypedSelector(store => store.pages.login.isLoading)
  const accessToken = useTypedSelector(store => store.auth.accessToken)
  const formErrorMessage = useTypedSelector(store => store.pages.login.formErrorMessage)
  const serverErrorMessage = useTypedSelector(store => store.pages.login.serverErrorMessage)
  const { loginUser, setFormErrorMessage } = useActions()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    loginUser({
      username: data.get('username') as string,
      password: data.get('password') as string
    })
  }

  const clearErrorMessage = () => {
    setFormErrorMessage(null)
  }

  if (accessToken) {
    return <Navigate to={{ pathname: pages.flatList }}/>
  }

  return (
    <PageWrapper>
      <Container component="main" maxWidth="xs">
        {serverErrorMessage &&
          <Alert severity="error">{serverErrorMessage}</Alert>}
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Вхід у панель адміністратора
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField margin="normal"
                       required
                       fullWidth
                       id="username"
                       label="Логін"
                       name="username"
                       error={!!formErrorMessage}
                       helperText={formErrorMessage}
                       onChange={clearErrorMessage}
                       autoFocus/>
            <TextField margin="normal"
                       required
                       fullWidth
                       name="password"
                       label="Пароль"
                       type="password"
                       id="password"
                       error={!!formErrorMessage}
                       helperText={formErrorMessage}
                       autoComplete="current-password"/>
            <FormControlLabel control={<Checkbox value="remember" color="primary"/>}
                              label="Запам'ятати мене"/>
            <Button type="submit" fullWidth
                    variant="contained" sx={{ mt: 3, mb: 2 }}
                    disabled={isLoading}>
              {isLoading && <CircularProgress size={25}/>}
              {!isLoading && 'Увійти'}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href={pages.register} variant="body2">Забули пароль?</Link>
              </Grid>
              <Grid item>
                <Link href={pages.register} variant="body2">Не маєте аккаунта? Зареєструватись</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </PageWrapper>
  )
}

export default Login
