import { put, takeLatest } from 'redux-saga/effects'
import { getFlatListReq, putFlatReq, removeFlatReq } from 'src/api/flat'
import * as actions from './slices'

export function* getArchiveListApi() {
  yield put(actions.setIsLoading(true))
  try {
    const { data } = yield getFlatListReq(null, true, null, null, null)
    yield put(actions.setFlatList(data.realties))
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* removeFlatFromArchiveApi(action) {
  yield put(actions.setIsLoading(true))
  const { payload: { flat } } = action
  try {
    yield putFlatReq(flat._id, { ...flat, archived: false })
    yield getArchiveListApi()
  } catch (err) {
    console.error(err)
  }
  yield put(actions.setIsLoading(false))
}

export function* removeFlatApi(action) {
  yield put(actions.setIsLoading(true))
  const { payload: { flatId } } = action
  try {
    yield removeFlatReq(flatId)
    yield getArchiveListApi()
  } catch (err) {
    console.error(err)
  }
  yield put(actions.setIsLoading(false))
}

export function* watchArchivesApi() {
  yield takeLatest(actions.getFlatList.type, getArchiveListApi)
  yield takeLatest(actions.removeFlatFromArchive.type, removeFlatFromArchiveApi)
  yield takeLatest(actions.removeFlat.type, removeFlatApi)
}
