import { Button, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { PageWrapper } from 'src/elements'
import { useTypedSelector } from 'src/hooks'
import Loader from 'src/elements/common/Loader'
import { isEmpty } from 'src/utils'
import { FlatForm } from '../../components'
import useActions from '../../store/useActions'
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const FlatEdit = () => {
  const { flatId } = useParams()
  const { getFlat, clearStore } = useActions()
  const isLoading = useTypedSelector(store => store.pages.flats.isLoading)
  const flat = useTypedSelector(store => store.pages.flats.flat)
  const navigate = useNavigate()

  useEffect(() => {
    getFlat({ flatId })

    return () => {
      clearStore()
    }
  }, [])

  const navigateBack = () => {
    navigate(-1)
  }

  return (
    <PageWrapper>
      <Button onClick={navigateBack} variant="contained" sx={{ marginBottom: '20px' }} color="secondary" startIcon={<ArrowBack/>}>
        Назад
      </Button>
      <Typography variant="h6">Редагувати оголошення №{flat?.publicId}</Typography>
      <Loader isLoading={isLoading}/>

      {!isEmpty(flat) &&
        <FlatForm initialFlat={flat}/>}
    </PageWrapper>
  )
}

export default FlatEdit
