import { put, select, takeLatest } from 'redux-saga/effects'
import * as actions from './slices'
import { getClientBalanceReq, getClientListReq, putClientBalanceReq } from '../../../api/client'

export function* getClientListApi(action) {
  yield put(actions.setIsLoading(true))
  const { payload: { phone, page, size } } = action
  try {
    const { data } = yield getClientListReq({ phone, page, size })
    yield put(actions.setClientList(data.clients))
    yield put(actions.setClientsTotal(data.totalCount))
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* appendClientListApi(action) {
  yield put(actions.setIsLoading(true))
  const { payload: { phone, page, size } } = action
  const clientList = yield select(state => state.pages.client.clientList)
  try {
    const { data } = yield getClientListReq({ phone, page, size })
    yield put(actions.setClientsTotal(data.totalCount))
    const resultClients = [...clientList, ...data.clients]
    yield put(actions.setClientList(resultClients))
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* getClientBalanceApi(action) {
  const { payload: { clientId } } = action
  try {
    const { data } = yield getClientBalanceReq(clientId)
    yield put(actions.setClientBalance({ balance: data.balance, bonusesBalance: data.bonusesBalance }))
  } catch (error) {
    console.error(error)
  }
}

export function* updateClientBalanceApi(action) {
  yield put(actions.setIsLoading(true))
  const { payload: { clientId, balance, bonuses } } = action
  try {
    yield putClientBalanceReq(clientId, { balance, bonuses })
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* watchClientApi() {
  yield takeLatest(actions.getClientList.type, getClientListApi)
  yield takeLatest(actions.appendClientList.type, appendClientListApi)
  yield takeLatest(actions.getClientBalance.type, getClientBalanceApi)
  yield takeLatest(actions.updateClientBalance.type, updateClientBalanceApi)
}
