import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getFlatListReq, getFlatReq, postFlatReq, presignImageReq, putFlatReq, putImageReq } from 'src/api/flat'
import * as actions from './slices'
import { goBack } from 'redux-first-history'

export function* getFlatListApi(action: ReturnType<typeof actions.getFlatList>) {
  yield put(actions.setIsLoading(true))
  const { payload: { city, publicId, crmSource } } = action
  try {
    const { data } = yield getFlatListReq(false, false, city, publicId, crmSource)
    yield put(actions.setFlatList(data.realties))
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* getFlatApi(action: ReturnType<typeof actions.getFlat>) {
  yield put(actions.setIsLoading(true))
  const { payload: { flatId } } = action
  try {
    const { data } = yield getFlatReq(flatId)
    yield put(actions.setFlat(data))
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* createFlatApi(action: ReturnType<typeof actions.createFlat>) {
  yield put(actions.setIsLoading(true))
  const { payload: { flat } } = action
  try {
    const { data: { id } } = yield postFlatReq(flat)
    const { data } = yield getFlatReq(id)
    yield put(actions.setFlat(data))
    yield put(goBack())
  } catch (err) {
    console.error(err)
  }
  yield put(actions.setIsLoading(false))
}

export function* updateFlatApi(action: ReturnType<typeof actions.updateFlat>) {
  yield put(actions.setIsLoading(true))
  const { payload: { flat } } = action
  try {
    yield putFlatReq(flat._id, flat)
    const { data } = yield getFlatReq(flat._id)
    yield put(actions.setFlat(data))
    yield put(goBack())
  } catch (err) {
    console.error(err)
  }
  yield put(actions.setIsLoading(false))
}

export function* removeFlatToArchiveApi(action: ReturnType<typeof actions.removeFlatToArchive>) {
  yield put(actions.setIsLoading(true))
  const { payload: { flat } } = action
  try {
    yield putFlatReq(flat._id, { ...flat, archived: true })
    yield getFlatListApi({ payload: { city: null, publicId: null, crmSource: null }, type: actions.getFlatList.type })
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* addFlatImagesApi(action: ReturnType<typeof actions.addFlatImages>) {
  yield put(actions.setIsLoading(true))
  const { payload: { files, callback } } = action
  try {
    const calls = Array.from(files).map(file => call(performFileUpload, { file }))
    const savedImages = yield all(calls)
    yield put(actions.setIsLoading(false))
    callback(savedImages)
  } catch (error) {
    console.error(error)
  }
}

export function* performFileUpload({ file }: any) {
  const nameSplit = file.name.split('.')
  const { data } = yield presignImageReq(nameSplit[nameSplit.length - 1])
  const { data: { secure_url } } = yield putImageReq(file, data)
  return secure_url
}

export function* watchFlatsApi() {
  yield takeLatest(actions.getFlatList.type, getFlatListApi)
  yield takeLatest(actions.getFlat.type, getFlatApi)
  yield takeLatest(actions.createFlat.type, createFlatApi)
  yield takeLatest(actions.updateFlat.type, updateFlatApi)
  yield takeLatest(actions.removeFlatToArchive.type, removeFlatToArchiveApi)
  yield takeLatest(actions.addFlatImages.type, addFlatImagesApi)
}
