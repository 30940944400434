import { Button, Typography } from '@mui/material'
import React from 'react'
import { PageWrapper } from 'src/elements'
import { useTypedSelector } from '../../../../hooks'
import Loader from '../../../../elements/common/Loader'
import { FlatForm } from '../../components'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'

const FlatCreate = () => {
  const isLoading = useTypedSelector(store => store.pages.flats.isLoading)
  const navigate = useNavigate()

  const navigateBack = () => {
    navigate('/')
  }

  return (
    <PageWrapper>
      <Button onClick={navigateBack} variant="contained" sx={{ marginBottom: '20px' }} color="secondary" startIcon={<ArrowBack/>}>
        Назад
      </Button>

      <Typography variant="h6" sx={{ marginBottom: '20px' }}>
        Нове оголошення:
      </Typography>
      <Loader isLoading={isLoading}/>

      <FlatForm initialFlat={{ images: [], additionalInfo: { wifi: false, boiler: false, washMachine: false, ac: false, furnished: false, tv: false }, amenities: {}, rooms: [], roomsNumber: null, photoSpheres: [], noCommission: false }}/>
    </PageWrapper>
  )
}

export default FlatCreate
