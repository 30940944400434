import { Box, Paper, Typography } from '@mui/material'
import React, { FC } from 'react'

type Props = {
  children: any,
  text
}

const FormGroup: FC<Props> = ({ children, text }) => {
  return (
    <Paper elevation={4} sx={{ px: '24px', py: '24px', mt: '24px' }}>
      <Typography variant="h6" sx={{ mb: '24px' }}>{text}</Typography>
      <Box marginBottom={1}>
        {children}
      </Box>
    </Paper>
  )
}

export { FormGroup }
