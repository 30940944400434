import { Box, Button, CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { PageWrapper } from 'src/elements'
import { useTypedSelector } from 'src/hooks'
import Loader from 'src/elements/common/Loader'
import useActions from '../../store/useActions'
import { AccountBalanceWallet } from '@mui/icons-material'
import ClientBalance from './Balance'

const ClientList = () => {
  const isLoading = useTypedSelector(state => state.pages.client.isLoading)
  const clientList = useTypedSelector(state => state.pages.client.clientList)
  const clientsTotal = useTypedSelector(state => state.pages.client.clientsTotal)
  const { getClientList, appendClientList } = useActions()
  const [phoneFilter, setPhoneFilter] = useState('')
  const [page, setPage] = useState(0)
  const [balanceUserId, setBalanceUserId] = useState(null)

  useEffect(() => {
    getClientList({ phone: phoneFilter, page, size: 50 })
  }, [phoneFilter])

  const handleLoadMore = () => {
    const nextPage = page + 1
    setPage(nextPage)
    appendClientList({ phone: phoneFilter, page: nextPage, size: 50 })
  }

  const openBalanceModal = (userId) => {
    setBalanceUserId(userId)
  }

  return (
    <PageWrapper>
      <Loader isLoading={isLoading}/>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
        <TextField
          label="Телефон"
          name="phone"
          sx={{ width: '200px', marginBottom: '20px' }}
          variant="outlined"
          value={phoneFilter}
          onChange={(e) => {
            // @ts-ignore
            setPhoneFilter(e.target.value)
          }}/>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell align="left">Телефон</TableCell>
              <TableCell align="left">Ім'я</TableCell>
              <TableCell align="left">Дата РеєстраціЇ</TableCell>
              <TableCell align="right">Дії</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {clientList?.map((row, i) => {
              return (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">{clientsTotal - i}</TableCell>
                  <TableCell align="left">{row.phone}</TableCell>
                  <TableCell align="left">{row.name} {row.surname}</TableCell>
                  <TableCell align="left">{row.createdAt}</TableCell>
                  <TableCell align="right" width="120px">
                    <IconButton aria-label="to-flats" onClick={() => openBalanceModal(row.id)}>
                      <AccountBalanceWallet/>
                    </IconButton>
                  </TableCell>
                </TableRow>)
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button type="button" variant="contained" sx={{ display: 'block', mt: 3, mb: 2, mx: 'auto' }} disabled={isLoading} onClick={handleLoadMore}>
        {isLoading && <CircularProgress size={25}/>}
        {!isLoading && 'Завантажити ще'}
      </Button>

      <ClientBalance {...{ balanceUserId, setBalanceUserId }} />
    </PageWrapper>
  )
}

export default ClientList
