import { createAction, createSlice, PayloadAction, } from '@reduxjs/toolkit'
import { removeFlatApi } from './sagas'

const prefix = 'pages/archives'

export const clearStore = createAction(`${prefix}/clearStore`)

const archivesSlice = createSlice({
  name: prefix,
  initialState: {
    isLoading: false as boolean,
    flatList: [] as any
  },
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setFlatList: (state, { payload }: PayloadAction<any>) => {
      state.flatList = payload
    }
  },
  extraReducers: {
    [clearStore.type]: () => ({
      isLoading: false,
      flatList: [],
    }),
  },
})

// actions
export const { setIsLoading, setFlatList } = archivesSlice.actions

export const getFlatList = createAction(`${prefix}/getFlatList`)
export const removeFlatFromArchive = createAction<any>(`${prefix}/removeFlatFromArchive`)
export const removeFlat = createAction<{ flatId: string }>(`${prefix}/removeFlat`)

export const archivesReducer = archivesSlice.reducer
