import { Navigate } from 'react-router-dom'
import React, { FC } from 'react'
import { useTypedSelector } from '../../../hooks'
import { pages } from '../../../utils'

type Props = {
  component: FC
}

const PrivatePage: FC<Props> = ({ component: Component }) => {
  const hasAccessToken = useTypedSelector(store => !!store.auth.accessToken)

  return (
    hasAccessToken ?
      <Component /> :
      <Navigate to={{ pathname: pages.login }} />
  )
}

export default PrivatePage
