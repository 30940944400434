import { CssBaseline } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import FlatCreate from 'src/pages/Flat/pages/Create'
import FlatEdit from 'src/pages/Flat/pages/Edit'
import FlatList from 'src/pages/Flat/pages/List'
import Login from './pages/Login'
import { GlobalStyle, pages } from './utils'
import PrivatePage from './elements/common/PrivatePage'
import Registration from './pages/Registration'
import BookingList from './pages/Booking/pages/List'
import SettingsList from './pages/Settings/pages/List'
import ArchiveList from './pages/Archive/pages/List'
import HostList from './pages/Host/pages/List'
import Notifications from './pages/Notifications/pages'
import ClientList from './pages/Client/pages/List'

function App() {
  return (
    <>
      <GlobalStyle/>
      <CssBaseline/>

      <Routes>
        <Route path={pages.login} element={<Login/>}/>
        <Route path={pages.register} element={<Registration/>}/>
        <Route path={pages.flatList} element={<PrivatePage component={FlatList}/>}/>
        <Route path={pages.flatCreate} element={<PrivatePage component={FlatCreate}/>}/>
        <Route path={pages.flatEdit} element={<PrivatePage component={FlatEdit}/>}/>
        <Route path={pages.bookingList} element={<PrivatePage component={BookingList}/>}/>
        <Route path={pages.archiveList} element={<PrivatePage component={ArchiveList}/>}/>
        <Route path={pages.clientList} element={<PrivatePage component={ClientList}/>}/>
        <Route path={pages.hostList} element={<PrivatePage component={HostList}/>}/>
        <Route path={pages.notifications} element={<PrivatePage component={Notifications}/>}/>
        <Route path={pages.settings} element={<PrivatePage component={SettingsList}/>}/>
      </Routes>
    </>
  )
}

export default App
