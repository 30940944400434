import React from 'react'
import { PageWrapper } from 'src/elements'

const SettingsList = () => {

  return (
    <PageWrapper> </PageWrapper>
  )
}

export default SettingsList
