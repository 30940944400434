import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, } from '@mui/material'
import React, { useEffect } from 'react'
import useActions from '../../../store/useActions'
import { useTypedSelector } from '../../../../../hooks'

const ClientBalance = ({ balanceUserId, setBalanceUserId }) => {
  const { getClientBalance, updateClientBalance, setClientBalance } = useActions()
  const { balance, bonusesBalance } = useTypedSelector(state => state.pages.client.clientBalance)
  const isLoading = useTypedSelector(state => state.pages.client.isLoading)

  useEffect(() => {
    if (balanceUserId) {
      getClientBalance({ clientId: balanceUserId })
    } else {
      setClientBalance({})
    }
  }, [balanceUserId])

  const handleBalanceChange = (e) => {
    setClientBalance({ balance: parseInt(e.target.value), bonusesBalance })
  }

  const handleBonusesChange = (e) => {
    const value = parseInt(e.target.value)
    setClientBalance({ balance, bonusesBalance: value })
  }

  const handleClose = () => {
    setBalanceUserId(null)
  }

  const handleSave = () => {
    updateClientBalance({ clientId: balanceUserId, balance, bonuses: bonusesBalance })
    getClientBalance({ clientId: balanceUserId })
  }

  return (
    <Dialog open={!!balanceUserId}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Баланс юзера</DialogTitle>
      <DialogContent sx={{ width: '400px' }}>
        <Box sx={{ mt: '10px', display: 'flex', alignItems: 'center', gap: '10px' }}>
          <TextField label="Баланс, грн"
                     name="balance"
                     sx={{ width: 'auto' }}
                     variant="outlined"
                     type="number"
                     value={balance || 0}
                     required={true}
                     onChange={handleBalanceChange}/>
          <TextField label="З них бонуси, грн"
                     name="bonusesBalance"
                     type="number"
                     sx={{ width: 'auto' }}
                     variant="outlined"
                     value={bonusesBalance || 0}
                     required={true}
                     onChange={handleBonusesChange}/>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Назад
        </Button>
        <Button onClick={handleSave} color="primary" autoFocus>
          {isLoading && <CircularProgress size={25}/>}
          {!isLoading && 'Зберегти'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ClientBalance
