import { push } from 'redux-first-history'
import { put, takeLatest } from 'redux-saga/effects'
import * as actions from './slices'

export function* pushSaga(action: ReturnType<typeof actions.push>) {
  const { payload: { path, search } } = action
  yield put(push({ pathname: path, search }))
}

export function* watchCommonApi() {
  yield takeLatest(actions.push.type, pushSaga)
}
