export const bookingStatusList = [
  { value: 'CONFIRMATION_PENDING', text: 'Обробляється' },
  { value: 'VIEWING_PENDING', text: 'Перегляд' },
  { value: 'VIEWING_COMPLETED', text: 'Перегляд відбувся' },
  { value: 'CHECK_IN_SUCCEEDED', text: 'Заселився' },

  { value: 'REALTY_NOT_AVAILABLE', text: 'Не актуальний обʼєкт' },
  { value: 'REJECTED', text: 'Скасована заявка' }
]

export const getBookingStatusText = (bookingStatus: string) => {
  return bookingStatusList.find(c => c.value === bookingStatus)?.text
}
