export const getUserAge = (dateString: string) => {
  if (!dateString) {
    return null
  }
  const dateParts = dateString.split('.')
  const birthDate = new Date(+dateParts[2], parseInt(dateParts[1]) - 1, +dateParts[0])
  const today = new Date()
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

export const getFormattedDate = (date) => {
  const inputDate = new Date(date)
  const year = inputDate.getFullYear()
  const month = String(inputDate.getMonth() + 1).padStart(2, '0') // Month is 0-indexed
  const day = String(inputDate.getDate()).padStart(2, '0')
  const hours = String(inputDate.getHours()).padStart(2, '0')
  const minutes = String(inputDate.getMinutes()).padStart(2, '0')
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`
  return formattedDate
}

export const getFormattedDateDay = (date) => {
  const inputDate = new Date(date)
  const month = String(inputDate.getMonth() + 1).padStart(2, '0') // Month is 0-indexed
  const day = String(inputDate.getDate()).padStart(2, '0')
  const hours = String(inputDate.getHours()).padStart(2, '0')
  const minutes = String(inputDate.getMinutes()).padStart(2, '0')
  const formattedDate = `${hours}:${minutes} (${day}.${month})`
  return formattedDate
}
