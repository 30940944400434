import { deleteRequest, getRequest, postRequest } from '../../utils'
import { bookingServiceUrl } from '../../urls'

export const getBookingListReq = (page = 0, size = 50) => {
  return getRequest(`${bookingServiceUrl}/admin/bookings`
    + (`?page=${page}`)
    + (`&size=${size}`))
}

export const removeBookingReq = bookingId => deleteRequest(`${bookingServiceUrl}/admin/bookings/${bookingId}`)

export const pushNotificationContactReq = bookingId => postRequest(`${bookingServiceUrl}/admin/bookings/${bookingId}/notifications/contact`, {})

export const pushNotificationNotActualReq = bookingId => postRequest(`${bookingServiceUrl}/admin/bookings/${bookingId}/notifications/notActual`, {})
