import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from './slices'

export default () => {
  const dispatch = useDispatch()

  return bindActionCreators(actions, dispatch)
}
