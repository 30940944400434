import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getItem, getToken, removeItem, removeToken, setItem, setToken } from '../../utils'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    username: (getItem('username') || null) as string,
    accessToken: getToken() as string
  },
  reducers: {
    setUsername: (state, { payload }: PayloadAction<string>) => {
      state.username = payload
      if (payload) {
        setItem('username', payload)
      } else {
        removeItem('username')
      }
    },
    setAccessToken: (state, { payload }: PayloadAction<string>) => {
      state.accessToken = payload
      if (payload) {
        setToken(payload)
      } else {
        removeToken()
      }
    }
  }
})

export const { setUsername, setAccessToken } = authSlice.actions
export const logoutUser = createAction('auth/logoutUser')

export const authReducer = authSlice.reducer
