import { put, takeLatest } from 'redux-saga/effects'
import * as actions from './slices'
import { setFormErrorMessage, setIsLoading, setServerErrorMessage } from './slices'
import { setAccessToken, setUsername } from '../../../store/auth/slices'
import { postLoginReq } from '../../../api/auth'

export function* loginUserSaga(action: ReturnType<typeof actions.loginUser>) {
  const { payload: { username, password } } = action
  yield put(setIsLoading(true))
  yield put(setUsername(username))
  try {
    let { data } = yield postLoginReq(username, password)
    yield put(setAccessToken(data.accessToken))
  } catch (err) {
    yield put(setFormErrorMessage(err.response?.data?.message))
    yield put(setServerErrorMessage(err.response?.data?.message))
  }
  yield put(setIsLoading(false))
}

export function* watchLoginApi() {
  yield takeLatest(actions.loginUser.type, loginUserSaga)
}
