import { Box, Container, createTheme, ThemeProvider } from '@mui/material'
import React, { FC } from 'react'
import { Header } from '../Header'

type Props = {
  children: React.ReactNode
}

const theme = createTheme()

export const PageWrapper: FC<Props> = ({ children }) => {

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ minHeight: '100vh', backgroundColor: theme => theme.palette.grey[100] }}>
        <Header/>
        <Box component="main" sx={{ paddingBottom: '0.01%' }}>
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            {children}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
